<template>
    <div
      class="section section--product bg-white"
      style="height: 100vh;"
    >
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              style="cursor: pointer;"
              @click="directPage"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Hubungkan Produk
            </h1>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button
              class="px-3"
              @click="createItem"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="8"
          >
            <b-card class="border">
                <div class="search__input w-100 mb-2">
                    <b-input-group >
                        <b-form-input
                            placeholder="Cari Produk Merchant"
                            v-model="searchQuery"
                        />
                    </b-input-group>
                </div>
                <b-row class="mb-1">
                    <b-col cols="6">
                        <div class="text-dark fw-bold-700">Produk Merchant</div>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-dark fw-bold-700">Produk Default</div>
                    </b-col>
                </b-row>
                <div>
                    <div class="text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" v-if="isLoading" />
                    </div>
                    <b-row v-for="(item, index) in formPayload.merchant_product_uuids" :key="index" v-if="!isLoading">
                        <b-col 
                            cols="6"
                            class="mb-2 custom__form--input"
                        >
                            <div>
                                <b-form-input
                                    class="custom__input"
                                    :value="item.name"
                                    disabled
                                />
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <v-select
                                v-model="formPayload.default_product_uuids[index]"
                                label="name" 
                                :options="optionsProduk"
                                placeholder="SKU Toqoo"
                                @search="onSearch"
                            />
                        </b-col>
                    </b-row>
                </div>
                <div class="text-center" v-if="!isLoading">
                    <b-button @click="loadMore" :disabled="isLoadingLoadMore">
                        {{ isLoadingLoadMore ? 'Loading...' : 'Load More' }}
                    </b-button>
                </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <Modal />
    </div>
  </template>
  
<script>
  /* eslint-disable vue/require-default-prop */
  // eslint-disable-next-line import/no-extraneous-dependencies
  import _ from 'lodash'
  import {
    BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BCard, BSpinner, BInputGroup, BInputGroupAppend
  } from 'bootstrap-vue'
  import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
  import { mapActions, mapState } from 'vuex'
  import vSelect from 'vue-select'
  import Modal from '@/components/Master/Produk/Modal.vue'
  
  export default {
    components: {
      vSelect,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BContainer,
      BCard,
      BSpinner,
      BInputGroup,
      BInputGroupAppend,
      Modal,
    },
    setup() {
      return {
        checkPermission, successNotification, errorNotification,
      }
    },
    data() {
      return {
        formPayload: {
          merchant_product_uuids: [],
          default_product_uuids: [],
        },
        messages: '',
        photo_url: '',
        editUuid: null,
        optionsProduk: [],
        editId: '',
        isLoading: false,
        page: 1,
        isLoadingLoadMore: false,
        next_page_url: '',
        searchQuery: '',
      }
    },
    computed: {
      ...mapState({
        products: state => state.produk.products,
        product: state => state.produk.product,
      }),
    },
    watch: {
      searchQuery: {
        handler: _.debounce(function (value) {
            this.getProdukMerchant()
        }, 300),
        deep: true,
      },
      editId(value) {
        this.editUuid = value
      },
      formData(value) {
        this.formPayload = value
      },
    //   products: {
    //     handler(value) {
    //       this.optionsProduk = value.data
    //     },
    //     deep: true,
    //   },
    },
    created() {
      this.getProdukMerchant()
      this.getProduk()
    },
    methods: {
      ...mapActions('produk', ['getData', 'showData', 'postData']),
      onSearch(search, loading) {
        if (search.length) {
          loading(true)
          this.search(loading, search, this)
        } else {
          loading(true)
          setTimeout(() => {
            this.optionsProduk = []
            this.getProduk()
            loading(false)
          }, 500)
        }
      },
      search: _.debounce((loading, search, vm) => {
        vm.getData({
          params: {
            product_type: 'default',
            search,
          },
        })
          .then(response => {
            // eslint-disable-next-line no-param-reassign
            vm.optionsProduk = response.data
            vm.$store.commit('produk/SET_PRODUCTS', response)
            loading(false)
          })
      }, 300),
      async getProduk() {
        const response = await this.getData({
          params: {
            product_type: 'default',
          },
        })
        this.optionsProduk = response.data
      },
      async getProdukMerchant() {
        this.isLoading = true;
        const response = await this.getData({
          params: {
            product_type: 'merchant',
            per_page: 10,
            has_related: 0,
            current_page: this.page,
            search: this.searchQuery
          },
        })
        this.formPayload.merchant_product_uuids = response.data;
        this.next_page_url = response.next_page_url;
        this.isLoading = false;
      },
      async createItem() {
        this.formPayload.merchant_product_uuids = this.formPayload.merchant_product_uuids.filter((item, index) => {
            return this.formPayload.default_product_uuids[index] !== null && this.formPayload.default_product_uuids[index] !== undefined;
        });

        this.formPayload.default_product_uuids = this.formPayload.default_product_uuids.filter(item => item !== null && item !== undefined);

        const form = this.preparePayload();
        this.isLoading = true;

        try {
            const response = await this.postData({
                id: 'bulk-link',
                payload: form,
            });

            this.isLoading = false;

            if (response) {
                this.$router.push({ name: 'master.product.index' });
                this.getProduk();
                successNotification(this, 'Success', 'Produk berhasil dihubungkan');
                this.cleanUpForm();
            }
        } catch (error) {
            // Handle the error here
            this.isLoading = false;
            this.getProdukMerchant();
            if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
            console.error(error);
        }
      },
      cleanUpForm() {
        this.formPayload = {
          merchant_product_uuids: [],
          default_product_uuids: [],
        }
        this.messages = ''
      },
      preparePayload() {
        const form = new FormData();
    
        if (Array.isArray(this.formPayload.merchant_product_uuids)) {
            this.formPayload.merchant_product_uuids.forEach((merchantUuid, index) => {
                form.append(`merchant_product_uuids[${index}]`, merchantUuid.uuid);
            });
        }
    
        if (Array.isArray(this.formPayload.default_product_uuids)) {
            this.formPayload.default_product_uuids.forEach((defaultUuid, index) => {
                form.append(`default_product_uuids[${index}]`, defaultUuid.uuid);
            });
        }
    
        // form.forEach((value, key) => {
        // console.log(`${key}: ${value}`);
        // });
        return form;
      },
      directPage() {
        this.cleanUpForm()
        this.$router.push({ name: 'master.product.index' })
      },

      async loadMore() {
        if (this.next_page_url) {
            this.isLoadingLoadMore = true;
            const queryParams = {
            product_type: 'merchant',
            per_page: 10,
            has_related: 0,
            };

            queryParams.search = this.searchQuery;
            queryParams.page = ++this.page;

            try {
            const result = await this.getData({
                params: queryParams,
            });

            // Append the new data to the existing data
            this.formPayload.merchant_product_uuids = [
                ...this.formPayload.merchant_product_uuids,
                ...result.data,
            ];

            this.next_page_url = result.next_page_url;
            } catch (err) {
            console.error(err);
            } finally {
            this.isLoadingLoadMore = false;
            }
        }
      },
  }
}
</script>
  
  <style lang="scss" scoped>
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@/assets/scss/variables/_variables.scss';
  </style>
  